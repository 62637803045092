import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DataTable } from 'primereact/datatable';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { getAllUsers } from '../../../services/user/user';
import { AutoComplete } from 'primereact/autocomplete';
import { getClientsByKey } from '../../../services/client/client';
import { currencyFormatter } from '../../../utils/currency';
import { getProformasByParams } from '../../../services/proforma/proforma';
import { messageError } from '../../../utils/messages';
import { getUserSession } from '../../../services/authentication';
import { routes } from '../../../utils/routes';
import esCalendar from '../../../translations/esCalendar';
import './list.css';
import { SALES_MANAGER_CODE, SALES_EXECUTIVE_CODE } from '../../../utils/constantsUser';
import { dateWithHour } from '../../../utils/functions';
import { InputText } from 'primereact/inputtext';
import _ from 'lodash';

function ProformaList({ history }) {
  const [users, setUsers] = useState([]);
  const [clients, setClients] = useState([]);
  const [proformas, setProformas] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showData, setShowData] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [params, setParams] = useState({
    status: '',
    idClient: '',
    createdBy: '',
    date: '',
    product: '',
    search: false
  });
  const { t } = useTranslation();
  const filters = [
    { label: t('pendingApproval'), value: '0' },
    { label: t('approved'), value: '1' },
    { label: t('sendToClient'), value: '2' },
    { label: t('rejected'), value: '3' },
    { label: t('reopened'), value: '4' }
  ];

  useEffect(() => {
    getAllUsers().then(result => {
      /* istanbul ignore next */
      const vendors = _.filter(result.data, { codeRole: SALES_EXECUTIVE_CODE });
      setUsers(vendors);
    }).catch(error => {
      (error.response) ? messageError(error.response.data.message) : messageError(t('withoutConnection'));
    });
  }, [t]);

  useEffect(() => {
    if (localStorage.getItem('paramsProforma') !== null) {
      const params = JSON.parse(localStorage.getItem('paramsProforma'));
      localStorage.setItem('paramsProforma', JSON.stringify({ ...params }));
      if (localStorage.getItem('selectedClientPF') !== null
        && params.idClient !== '') {
        setSelectedClient(JSON.parse(localStorage.getItem('selectedClientPF')));
      } else {
        localStorage.setItem('selectedClientPF', '');
      }
      if (localStorage.getItem('selectedStatusPF') !== null
        && params.status !== '') {
        setSelectedStatus(JSON.parse(localStorage.getItem('selectedStatusPF')));
      } else {
        localStorage.setItem('selectedStatusPF', JSON.stringify({}));
      }
      if (localStorage.getItem('selectedUserPF') !== null
        && params.createdBy !== '') {
        setSelectedUser(JSON.parse(localStorage.getItem('selectedUserPF')));
      } else {
        localStorage.setItem('selectedUserPF', JSON.stringify({}));
      }
      setParams(params);
      if (params.search) {
        searchProformas(params);
      }
    } else {
      clean();
    }
  }, []);

  const actionEdit = (rowData) => {
    return <div style={{ content: 'center', textAlign: 'center' }} id={'div-' + rowData.id}>
      <Button
        onClick={() => updateProforma(rowData)}
        type="button"
        icon="pi pi-pencil"
        id='button-edit'
        className="p-button-primary"
      >
        <span className="tooltiptext tooltiptext-bottom"></span>
      </Button>
    </div>;
  };

  const updateProforma = (proforma) => {
    history.push({
      pathname: routes.ProformaEdit,
      state: { proformaUpdate: proforma }
    });
  };

  const searchProformas = (searchParams = '') => {
    let postParams = searchParams !== '' ? { ...searchParams } : { ...params };
    setLoading(true);
    localStorage.setItem('paramsProforma', JSON.stringify({ ...postParams, search: true }));
    delete postParams.search;
    getProformasByParams(postParams).then(result => {
      setLoading(false);
      setProformas(result.data);
      setShowData(true);
    }).catch(error => {
      setLoading(false);
      cleanLocalStore();
      if (error.response) {
        setLoading(false);
        setShowData(true);
        setProformas([]);
        messageError(error.response.data.message);
      }
      else {
        setLoading(false);
        messageError(t('withoutConnection'));
      }
    });
  };

  const handleClientOnChange = (e) => {
    getClientsByKey(e.query).then(result => {
      setClients(result.data);
    });
  };

  const cleanLocalStore = () => {
    localStorage.setItem('selectedClientPF', '');
    localStorage.setItem('selectedStatusPF', JSON.stringify({}));
    localStorage.setItem('selectedUserPF', JSON.stringify({}));
    localStorage.setItem('paramsProforma', JSON.stringify({
      status: '',
      idClient: '',
      createdBy: '',
      date: '',
      search: false
    }));
  };

  const clean = () => {
    setSelectedClient('');
    setSelectedStatus({});
    setSelectedUser({});
    setParams({
      status: '',
      idClient: '',
      createdBy: '',
      date: '',
      product: '',
      search: false
    });
    cleanLocalStore();
  };

  const showState = (rowData) => {
    switch (rowData.status) {
    case 0:
      return t('pendingApproval');
    case 1:
      return t('approved');
    case 2:
      return t('sendedToClient');
    case 3:
      return t('rejected');
    case 4:
      return t('reopened');
    default:
      return t('rejected');
    }
  };

  const getDate = (rowData) => {
    if (getUserSession().user.codeRole === SALES_MANAGER_CODE) {
      return dateWithHour(rowData.createdOn);
    }
    let d = new Date(rowData.createdOn),
      day = '' + d.getDate(),
      month = '' + (d.getMonth() + 1),
      year = d.getFullYear();
    if (month.length < 2) { month = '0' + month; }
    if (day.length < 2) { day = '0' + day; }
    return [day, month, year].join('/');
  };

  const getIdentifier = (rowData) => {
    return rowData.sellerFirstName
      ? `${rowData.sellerFirstName[0]}${rowData.sellerLastName[0]}-${rowData.id}` : '';
  };

  const roundTotal = (rowData) => {
    return currencyFormatter.format(Number(Math.round(rowData.total * 100) / 100).toFixed(2));
  };

  const addProforma = () => {
    history.push(routes.ProformaRegister);
  };

  return (
    <div>
      <div className="ui-g ui-fluid" >
        <div className="ui-g-12">
          <div className="card">
            <h1>{t('proformaList')}</h1>
            <div className="ui-g-12 ui-md-7 ui-lg-2">
              <div className="p-grid p-fluid">
                <div className="p-col-6 p-md-2">
                  <div className="p-inputgroup">
                    <span className="p-label">
                      <label htmlFor="calendar">{t('date')}</label>
                      <Calendar
                        locale={esCalendar}
                        dateFormat="dd/mm/yy"
                        id='calendar'
                        selectionMode="range"
                        value={params.date}
                        className="dropdown-all-width"
                        onChange={(e) => {
                          setParams({ ...params, date: e.value });
                        }}
                      />
                    </span>
                  </div>
                </div>
                {getUserSession().user.codeRole === 'GV' &&
                  <div className="p-col-6 p-md-2">
                    <div className="p-inputgroup">
                      <span className="p-label">
                        <label htmlFor="createdBy">{t('createdBy')}</label>
                        <Dropdown
                          key="id"
                          id='createdBy'
                          value={selectedUser}
                          options={users}
                          optionLabel="fullName"
                          optionValue="id"
                          className="dropdown-all-width"
                          onChange={(event) => {
                            setSelectedUser(event.target.value);
                            localStorage.setItem('selectedUserPF', JSON.stringify(event.target.value));
                            setParams({ ...params, createdBy: event.target.value.id });
                          }}
                        />
                      </span>
                    </div>
                  </div>
                }
                {getUserSession().user.codeRole !== 'CL' &&
                  <>
                    <div className="p-col-6 p-md-2">
                      <div className="p-inputgroup">
                        <span className="p-label">
                          <label htmlFor="status">{t('status')}</label>
                          <Dropdown
                            id='status'
                            value={selectedStatus}
                            options={filters}
                            optionLabel="label"
                            optionValue="value"
                            className="dropdown-all-width"
                            onChange={(event) => {
                              setSelectedStatus(event.target.value);
                              localStorage.setItem('selectedStatusPF', JSON.stringify(event.target.value));
                              setParams({ ...params, status: event.target.value.value });
                            }}
                          />
                        </span>
                      </div>
                    </div>
                    <div className="p-col-6 p-md-3">
                      <div className="p-inputgroup">
                        <span className="p-label">
                          <label htmlFor="client">{t('client')}</label>
                          <AutoComplete
                            id='client'
                            value={selectedClient}
                            field="name"
                            onSelect={(e) => {
                              setSelectedClient(e.value);
                              localStorage.setItem('selectedClientPF', JSON.stringify(e.value));
                              setParams({ ...params, idClient: e.value.id });
                            }}
                            suggestions={clients}
                            onChange={(e) => setSelectedClient(e.value)}
                            completeMethod={(e) => handleClientOnChange(e)}
                            className="dropdown-all-width"
                          />
                        </span>
                      </div>
                    </div>
                  </>
                }
                <div className="p-col-6 p-md-3">
                  <div className="p-inputgroup">
                    <span className="p-label">
                      <label htmlFor="product">{t('product')}</label>
                      <InputText id='product' value={params.product} className="dropdown-all-width"
                        onChange={(event) => setParams({ ...params, product: event.target.value })} />
                    </span>
                  </div>
                </div>
                <div className="p-col-12 p-md-12">
                  <div className="p-inputgroup-button">
                    <Button
                      icon="pi pi-search"
                      onClick={() => searchProformas()}
                      label={t('search')}
                      style={{ marginBottom: '10px', width: 'auto' }}
                      className="tooltip button-table"
                    />
                    <Button
                      icon="pi pi-refresh"
                      onClick={clean}
                      label={t('clean')}
                      style={{ marginBottom: '10px', width: 'auto' }}
                      className="tooltip button-table"
                    />
                    {getUserSession().user.codeRole !== 'CL' &&
                      <Button
                        icon="pi pi-plus"
                        onClick={addProforma}
                        label={t('newProforma')}
                        style={{ marginBottom: '10px', width: 'auto' }}
                        className="tooltip button-table"
                      />
                    }
                  </div>
                </div>
              </div>
            </div>
            <div className="ui-g-12">
              {loading &&
                <div id="div-loader" className="spiner-container">
                  <i className="pi pi-spin pi-spinner spiner" />
                </div>
              }
              {!loading && showData &&
                <DataTable paginator={true} emptyMessage={t('emptyList')} rows={10} responsive={true} value={proformas}>
                  <Column sortField={getDate} sortable className="ui-column-date" body={getDate} header={t('date')} />
                  <Column sortable className="ui-column" field="clients.name" header={t('client')} />
                  <Column sortable sortField={showState} className="ui-column"
                    field="status" header={t('status')} body={showState} />
                  {getUserSession().user.codeRole === SALES_MANAGER_CODE
                    && <Column
                      sortable
                      className="ui-column"
                      body={getIdentifier}
                      sortField={getIdentifier}
                      header={t('consecutive')} />}
                  <Column sortable sortField="total" className="ui-column-total" body={roundTotal} header={t('total')} />
                  <Column className="ui-column-edit" header={t('edit')} body={actionEdit} />
                </DataTable>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProformaList;
