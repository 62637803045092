export const dateWithHour = (date) => {
  let d = new Date(date),
    day = '' + d.getDate(),
    month = '' + (d.getMonth() + 1),
    year = d.getFullYear(),
    hours = d.getHours(),
    minutes = '' + d.getMinutes();

  if (month.length < 2) { month = '0' + month; }
  if (day.length < 2) { day = '0' + day; }
  if (minutes.length < 2) { minutes = '0' + minutes; }

  let ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12;

  return [day, month, year].join('/') + ' ' + hours + ':' + minutes + ' ' + ampm;
};

export const updateSubtotal = (array, index, newValue) => {
  return array.map((obj, i) => {
    if (i === index) {
      return { ...obj, amount: newValue };
    }
    return obj;
  });
};
